import {mapActions, mapGetters, mapMutations} from "vuex";
import {VueSlideToggle} from 'vue-slide-toggle';
import smallLoader from '@/components/small-loader/index.vue';
//sections


export default {
    name: "products",
    props: ['productGroupsItem'],
    components: {
        VueSlideToggle,
        smallLoader
    },
    data() {
        return {
            openProductMenuIds: [],
            openMobileProductMenuIds: [],
            productRequestFlagID: 0,
            productsForBasket: {products: []}
        }
    },
    created() {
        this.productsForBasket.products = this.basket.map(el => ({id: el.id, quantity: el.quantity}))
    },
    computed: {
        ...mapGetters({
            isShowRetailColumn: 'profile/isShowRetailPrice',
            basket: 'basket/basket',
        })
    },
    watch: {
        productGroupsItem(data) {
            const basketIds = this.basket.map(el => el.id)
            data.products.data.forEach(el => {
                if (basketIds?.includes(el.id)) {
                    const searchedBasketElement = this.basket.find(val => val.id === el.id)
                    return el.count = searchedBasketElement.quantity
                }
            })
        },
        basket(data) {
            this.productsForBasket.products = data.map(el => ({id: el.id, quantity: el.quantity}))
        }
    },
    methods: {
        ...mapMutations({
            incrementBasketListCount: 'basket/INCREMENT_BASKET_LIST_COUNT',
            decrementBasketListCount: 'basket/DECREMENT_BASKET_LIST_COUNT',
        }),
        ...mapActions({
            basketCount: 'basket/BASKET_PRODUCT_ACTION',
            updateBasket: 'basket/UPDATE_BASKET'
        }),
        incProductCount(productID) {
            this.productGroupsItem.products.data
                .forEach(item => item.id === productID ? item.count += 1 : item.count)
        },
        changeProductCount(product, changer) {
            switch (changer) {
                case 'minus': {
                    this.productGroupsItem.products.data.forEach(item => {
                        if (item.id === product.id) {
                            if (item.count > 1) return item.count -= 1
                        }
                    })
                    if (this.searchInBasketProduct(product.id)) {
                        this.basketCount({product_id: product.id, type: 'decrement'})
                    }
                    this.decrementBasketListCount(this.searchInBasketProduct(product.id))
                    break
                }
                case 'plus': {
                    if (product.productStatus.key === 'not_available') {
                        return
                    }
                    this.incProductCount(product.id)
                    if (this.searchInBasketProduct(product.id)) {
                        this.basketCount({product_id: product.id, type: 'increment'})
                        this.incrementBasketListCount(this.searchInBasketProduct(product.id))
                    } else {
                        this.productsForBasket.products.push({id: product.id, quantity: product.count})
                    }
                    break
                }
            }
        },
        toBasket(product) {
            if (product.productStatus.key === 'not_available') {
                return
            }
            this.productRequestFlagID = product.id
            if (this.searchInBasketProduct(product.id)) {
                this.basketCount({product_id: product.id, type: 'increment'}).then(() => {
                    this.incProductCount(product.id)
                    this.$toasted.success(this.$t('basket.successAddBasket'))
                    this.productRequestFlagID = 0
                })
            } else {
                this.productsForBasket.products.push({id: product.id, quantity: product.count})
                this.updateBasket(this.productsForBasket).then(() => {
                    this.$toasted.success(this.$t('basket.successAddBasket'))
                    this.productRequestFlagID = 0
                })
            }
        },
        onOpenMobileProductMenu(menuId) {
            if (this.openMobileProductMenuIds.includes(menuId)) {
                this.openMobileProductMenuIds = this.openMobileProductMenuIds.filter(id => id !== menuId)
            } else {
                this.openMobileProductMenuIds.push(menuId)
            }
        },
        searchInBasketProduct(productId) {
            return this.basket.find(prod => prod.id === productId)
        },
        productStatusColor(key) {
            switch (key) {
                case 'in_stock': {
                    return '#5ED84B'
                }
                case 'not_available': {
                    return 'red'
                }

            }
        }
    }
}
